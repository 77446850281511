import planet from '../assets/images/homePageImages/homePageImage1.png';
import brand from '../assets/images/homePageImages/homePageImage2.png';
import logo from '../assets/images/homePageImages/homePageImage3.png';
import computer from '../assets/images/homePageImages/homePageIcon1.png';
import mobile from '../assets/images/homePageImages/homePageIcon2.png';
import cloud from '../assets/images/homePageImages/homePageIcon3.png';
import hexagon from '../assets/images/homePageImages/homePageIcon4.png';
import gear from '../assets/images/homePageImages/homePageIcon5.png';
import bulb from '../assets/images/homePageImages/homePageIcon6.png';
import mobilePlanet from '../assets/images/homePageImages/homePageImage4.png';


const imagesHomePage = {
    planet,
    brand,
    logo,
    computer,
    mobile,
    cloud,
    hexagon,
    gear,
    bulb,
    mobilePlanet
};

export default imagesHomePage;