import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from "./HomePage.module.scss";
import Button from "../components/Buttons/Buttons";
import ServiceCard from '../components/ServiceCard/ServiceCard';
import CarouselSlide from '../components/Carousel/Carousel';
import images from '../constants/imagesHomePage';
import Header from '../pages/LayoutPage/Header';
import { EHeaderTab } from '../types/pageLayout';
import { navigateHeader } from '../utils/navigateHeader';
import useLoading from '../hooks/useLoading';

const HomePage: React.FC = () => {
  useLoading();

  const navigate = useNavigate(); 

  const [backgroundImage, setBackgroundImage] = useState(images.planet);

    useEffect(() => {
        const updateImage = () => {
            setBackgroundImage(window.innerWidth <= 480 ? images.mobilePlanet : images.planet);
        };

        updateImage();
        window.addEventListener('resize', updateImage);

        return () => window.removeEventListener('resize', updateImage);
    }, []);
    
    return (
        <body className={styles.body}>
          <Header activeTab={EHeaderTab.HOME} onClick={(tab: EHeaderTab) => navigateHeader(tab, navigate)} />
          <section className={styles.heroContent}>
            <div className={styles.heroText}>
              <h1 className={styles.heroTitle}>Giải pháp phần mềm cho doanh nghiệp vừa và nhỏ</h1>
              <p className={styles.description}>
              Arisen giúp doanh nghiệp chuyển đổi số toàn diện với các giải pháp
              phần mềm chuyên biệt, tối ưu quy trình và nâng cao trải nghiệm.
              </p>
            </div>
    
            <div className={styles.buttonContainer}>
              <Link to="/contact">
                <Button variant="orange" text="Nhận tư vấn miễn phí" />
              </Link>              
              <Button variant="black" text="Hotline: 1900 0088"/>
            </div>
            
            <div className={styles.partners}>
              <div className={styles.backgroundContainer}>
              <img src={backgroundImage} alt="Background" className={styles.backgroundImage} />
              <div className={styles.overlayText}>
                  Được tin tưởng bởi hơn 1000 đối tác
                </div>
                <div className={styles.brandLogos}>
                  <img src={images.brand} alt="Brand Logos" className={styles.brandsImage} />
                </div>
              </div>
            </div>
          </section>
          <section className={styles.servicesSection}>
            <ServiceCard />
          </section>
        <section className={styles.carouselSection}>
          <h2 className={styles.carouselTitle}>
            Tại sao Arisen là lựa chọn <span className={styles.numberOne}>số 1?</span>
            </h2>          
            <CarouselSlide />
        </section>
        </body>
      );
    };
    
    export default HomePage;
