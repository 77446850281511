import React, { useState } from 'react';
import styles from './Header.module.scss';
import { EHeaderTab } from '../../types/pageLayout';
import headerpng from '../../assets/images/Header/HeaderLogo.png';
import { SECONDARY_COLOR } from '../../constants/colors';

interface IHeaderProps {
    activeTab: EHeaderTab;
    onClick?: (tab: EHeaderTab) => void;
}

const Header = (props: IHeaderProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div id="header_id" className={`${styles.container} ${props.activeTab === EHeaderTab.CONTACT ? styles.contactActive : ''}`}>
            <div className={styles.logo}>
                <img src={headerpng} alt="ARISEN" />
            </div>
            
            <div className={styles.menuIcon} onClick={handleToggleDropdown}>
                ☰
            </div>

            <div className={`${styles.pageNavigate} ${isDropdownOpen ? styles.show : ''}`} style={{ color: SECONDARY_COLOR }}>
                <div className={styles.closeIcon} onClick={handleToggleDropdown}>
                    ✕
                </div>
                <div
                    className={`${styles.link} ${props.activeTab === EHeaderTab.HOME ? styles.active : ''}`}
                    onClick={() => props.onClick && props.onClick(EHeaderTab.HOME)}
                >
                    Trang chủ
                </div>
                <div
                    className={`${styles.link} ${props.activeTab === EHeaderTab.SERVICE ? styles.active : ''}`}
                    onClick={() => props.onClick && props.onClick(EHeaderTab.SERVICE)}
                >
                    Dịch vụ
                </div>
                <div
                    className={`${styles.link} ${props.activeTab === EHeaderTab.ABOUT ? styles.active : ''}`}
                    onClick={() => props.onClick && props.onClick(EHeaderTab.ABOUT)}
                >
                    Về chúng tôi
                </div>
                <div
                    className={`${styles.link} ${props.activeTab === EHeaderTab.CONTACT ? styles.active : ''}`}
                    onClick={() => props.onClick && props.onClick(EHeaderTab.CONTACT)}
                >
                    Liên hệ
                </div>
            </div>
        </div>
    );
};

export default Header;
