import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import ContactPage from "./pages/ContactPage";
import PageLayout from "./pages/LayoutPage/PageLayout";
import useLoading from "./hooks/useLoading";

function App() {
	useLoading();
	
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={
					<PageLayout>
						<HomePage />
					</PageLayout>
				} />
				<Route path="/home" element={
					<PageLayout>
						<HomePage />
					</PageLayout>
				} />
				<Route path="/about" element={
					<PageLayout>
						<AboutPage />
					</PageLayout>
				} />
				<Route path="/service" element={
					<PageLayout>
						<ServicePage />
					</PageLayout>
				} />
				<Route path="/contact" element={
					<PageLayout>
						<ContactPage />
					</PageLayout>
				} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
