import titleBg from '../assets/images/AboutPage/TtitleBg.png';
import groupPic from '../assets/images/AboutPage/GroupPic.png';
import valueImage1 from '../assets/images/AboutPage/ValueImg1.png';
import valueImage2 from '../assets/images/AboutPage/ValueImg2.png';
import valueImage3 from '../assets/images/AboutPage/ValueImg3.png';
import valueImage4 from '../assets/images/AboutPage/ValueImg4.png';
import placeholder from '../assets/images/AboutPage/Placeholder.png';

const imagesAboutPage = {
    titleBg,
    groupPic,
    valueImage1,
    valueImage2,
    valueImage3,
    valueImage4,
    placeholder,
};

export default imagesAboutPage;