import { useState, useRef } from 'react';
import styles from './Carousel.module.scss';
import images from '../../constants/imagesHomePage';

const CarouselSlide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesContainerRef = useRef<HTMLDivElement>(null);

  const slides = [
    {
      title: 'Đội ngũ chuyên gia với kinh nghiệm dày dặn',
      description: 'Arisen tự hào có chuyên gia công nghệ giàu kinh nghiệm, đồng hành giúp tối ưu tiềm năng doanh nghiệp.',
      icon: images.hexagon,
    },
    {
      title: 'Sự cam kết đồng hành và hỗ trợ toàn diện',
      description: 'Arisen luôn đồng hành cùng bạn từ bước lập kế hoạch, triển khai đến bảo trì giải pháp toàn diện.',
      icon: images.gear,
    },
    {
      title: 'Giải pháp chuyển đổi số tích hợp',
      description: 'Arisen tối ưu quy trình và tăng hiệu suất giúp doanh nghiệp phát triển mạnh mẽ trong kỷ nguyên số.',
      icon: images.bulb,
    }
  ];

  const scrollToSlide = (slideIndex: number) => {
    if (slidesContainerRef.current && window.innerWidth <= 768) {
      const slideWidth = slidesContainerRef.current.offsetWidth;
      slidesContainerRef.current.scrollTo({
        left: slideWidth * slideIndex,
        behavior: 'smooth',
      });
    }
    setCurrentSlide(slideIndex);
  };

  return (
    <div className={styles.container}>

      <div className={styles.slideContainer} ref={slidesContainerRef}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles.slide} ${index === currentSlide ? styles.active : ''}`}
            style={{
              display: index === currentSlide ? 'flex' : 'none',
            }}
          >
            <div className={styles.textSection}>
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
            <div className={styles.iconSection}>
              <img src={slide.icon} alt={`${slide.title} Icon`} className={styles.icon} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.indicators}>
        {slides.map((_, index) => (
          <span
            key={index}
            onClick={() => scrollToSlide(index)}
            className={index === currentSlide ? styles.activeDot : styles.dot}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default CarouselSlide;
