import React from "react";
import styles from './Member.module.scss';

interface MemberInfo {
    img: string;
    name: string;
    role: string;
}

const Member: React.FC<MemberInfo> = ({img, name, role}) => {
    return (
        <div className={styles.Container}>
            <img src={img} alt={name} />
            <div className={styles.Text}>
                <h3>{name}</h3>
                <p>{role}</p>
            </div>
        </div>
    )
}

export default Member;