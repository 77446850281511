import React, { useState, useRef } from "react";
import styles from "./ResponsiveSlider.module.scss";
import Member from "../Member/Member";
import imagesAboutPage from "../../../constants/imagesAboutPage";

const slides = [
  { img: imagesAboutPage.placeholder, name: "Nguyễn Văn A", role: "CTO - Giám đốc công nghệ" },
  { img: imagesAboutPage.placeholder, name: "Nguyễn Văn A", role: "CTO - Giám đốc công nghệ" },
  { img: imagesAboutPage.placeholder, name: "Nguyễn Văn A", role: "CTO - Giám đốc công nghệ" },
  { img: imagesAboutPage.placeholder, name: "Nguyễn Văn A", role: "CTO - Giám đốc công nghệ" },
];

const SwipeableSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const startX = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const endX = e.changedTouches[0].clientX;
    const diffX = startX.current - endX;

    if (diffX > 50) goToNextSlide();
    else if (diffX < -50) goToPrevSlide();
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className={styles.Container}
      ref={sliderRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.Members}
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {slides.map((member, index) => (
          <div className={styles.Slider}
            key={index}
          >
            <Member img={member.img} name={member.name} role={member.role} />
          </div>
        ))}
      </div>
      <div className={styles.Dots}>
        {slides.map((_, index) => (
          <span
            key={index}
            style={{
              backgroundColor: currentIndex === index ? "#ccc" : "#333"
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SwipeableSlider;