import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKGROUND_COLOR, MAIN_COLOR, SECONDARY_COLOR } from '../constants/colors';
import styles from './AboutPage.module.scss';
import Value from '../components/AboutPage/Value/Value';
import Member from '../components/AboutPage/Member/Member';
import Goal from '../components/AboutPage/Goals/Goals';
import imagesAboutPage from '../constants/imagesAboutPage';
import SwipeableSlider from '../components/AboutPage/TestSlider/ResponsiveSlider'
import Header from '../pages/LayoutPage/Header';
import { EHeaderTab } from '../types/pageLayout';
import { navigateHeader } from '../utils/navigateHeader';
import useLoading from '../hooks/useLoading';

const AboutPage = () => {
    useLoading();

    const navigate = useNavigate();

    return (
        <div className={styles.Container} style={{backgroundColor: BACKGROUND_COLOR}}>
            <Header activeTab={EHeaderTab.ABOUT} onClick={(tab: EHeaderTab) => navigateHeader(tab, navigate)} />
            <section className={styles.Title}>
                <h1 style={{color: SECONDARY_COLOR}}>
                    Arisen - Giải pháp phần mềm tối ưu hoá cho doanh nghiệp vừa và nhỏ
                </h1>
            </section>
            <section className={styles.Hello}>
                <p style={{color: SECONDARY_COLOR}}>
                    <span style={{color: MAIN_COLOR}}>Xin chào! Chúng tôi là Arisen.</span>
                    <br />
                    <br />
                    Không chỉ là một công ty phần mềm – chúng tôi là một đội ngũ đam mê với sứ mệnh giúp các doanh nghiệp vừa và nhỏ (SMEs) phát triển. Hành trình của chúng tôi bắt đầu từ một niềm tin đơn giản: công nghệ có thể tạo ra sự khác biệt lớn lao cho những doanh nghiệp vừa và nhỏ.
                </p>
            </section>
            <img src={imagesAboutPage.groupPic} alt="Group_Employees" className={styles.GroupPic}/>
            <section className={styles.Value}>
                <h2 style={{color: SECONDARY_COLOR}}>4 giá trị cốt lõi của Arisen</h2>
                <div className={styles.Values}>
                    <Value
                        img={imagesAboutPage.valueImage1}
                        heading="Khách hàng là trung tâm"
                        description="Chúng tôi luôn lắng nghe và thấu hiểu bạn, đảm bảo giải pháp mang lại giá trị thiết thực."
                    />
                    <Value
                        img={imagesAboutPage.valueImage2}
                        heading="Đổi mới sáng tạo"
                        description="Luôn cập nhật công nghệ mới nhất, mang đến giải pháp phần mềm hiệu quả và tiên tiến."
                    />
                    <Value
                        img={imagesAboutPage.valueImage3}
                        heading="Chất lượng hàng đầu"
                        description="Chúng tôi chú trọng từng chi tiết, đảm bảo sản phẩm chất lượng và trải nghiệm khách hàng tuyệt vời."
                    />
                    <Value
                        img={imagesAboutPage.valueImage4}
                        heading="Hợp tác và cùng phát triển"
                        description="Sự hợp tác và chia sẻ là chìa khóa thành công, chúng tôi luôn xem bạn là đối tác lâu dài."
                    />
                </div>
            </section>
            <section className={styles.Team}>
                <div className={styles.Text}>
                    <h2 style={{color: SECONDARY_COLOR}}>Đội ngũ chuyên gia</h2>
                    <p style={{color: SECONDARY_COLOR}}>
                        Arisen sở hữu đội ngũ chuyên gia đam mê công nghệ, luôn học hỏi và phát triển để mang lại giải pháp tối ưu cho doanh nghiệp.
                    </p>
                </div>
                <SwipeableSlider />
                <div className={styles.Members}>
                    <Member
                        img={imagesAboutPage.placeholder}
                        name="Nguyễn Văn A"
                        role="CTO - Giám đốc công nghệ"
                    />
                    <Member
                        img={imagesAboutPage.placeholder}
                        name="Nguyễn Văn A"
                        role="CTO - Giám đốc công nghệ"
                    />
                    <Member
                        img={imagesAboutPage.placeholder}
                        name="Nguyễn Văn A"
                        role="CTO - Giám đốc công nghệ"
                    />
                    <Member
                        img={imagesAboutPage.placeholder}
                        name="Nguyễn Văn A"
                        role="CTO - Giám đốc công nghệ"
                    />
                </div>
            </section>
            <section className={styles.Goals}>
                <div className={styles.Container}>
                    <Goal
                        main="Tầm nhìn"
                        description="Chúng tôi hướng đến trở thành đối tác tin cậy cho doanh nghiệp vừa và nhỏ, mang lại công nghệ tiên tiến và tạo ra ảnh hưởng tích cực. Arisen giúp hiện thực hóa ước mơ kinh doanh bằng các giải pháp sáng tạo."
                    />
                    <Goal
                        main="Sứ mệnh"
                        description="Arisen giúp doanh nghiệp phát triển với giải pháp phần mềm tùy chỉnh, an toàn và hiệu quả. Chúng tôi luôn lắng nghe, thấu hiểu và đồng hành cùng bạn, lấy sự hài lòng của bạn làm động lực."
                    />
                </div>
            </section>
        </div>
    )
}

export default AboutPage;
