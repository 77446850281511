import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styles from './ContactPage.module.scss';
import { ReactComponent as ArrowIcon } from '../assets/images/homePageImages/homePageButtonIcon2.svg';
import Icon1 from '../assets/images/contactPageImages/contactPageIcon1.png';
import Header from '../pages/LayoutPage/Header';
import { EHeaderTab } from '../types/pageLayout';
import emailjs from 'emailjs-com';
import { navigateHeader } from '../utils/navigateHeader';
import useLoading from '../hooks/useLoading';

const ResetMapCenter = ({ center }: { center: LatLngTuple }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [map, center]);
  return null;
};

const ContactPage: React.FC = () => {
  useLoading();

  const fixedPosition: LatLngTuple = [21.028511, 105.804817];
  const [formData, setFormData] = useState({
    phone: '',
    email: '',
    name: '',
    serviceType: '',
    details: '',
  });
  const [errors, setErrors] = useState({
    phone: '',
    email: '',
    name: '',
    serviceType: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone: string) => {
    const re = /^\d+$/;
    return re.test(String(phone));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Custom validation to check if all required fields are filled
    const errors: { phone: string; email: string; name: string; serviceType: string } = {
      phone: formData.phone ? '' : 'Số điện thoại không được để trống',
      email: formData.email ? '' : 'Email không được để trống',
      name: formData.name ? '' : 'Họ và tên không được để trống',
      serviceType: formData.serviceType ? '' : 'Loại dịch vụ không được để trống',
    };

    // Additional validation for email and phone formats
    const emailValid = validateEmail(formData.email);
    const phoneValid = validatePhone(formData.phone);

    if (!emailValid) errors.email = 'Mail không hợp lệ';
    if (!phoneValid) errors.phone = 'Số điện thoại không hợp lệ';

    // Check if there are any errors
    if (Object.values(errors).some((error) => error)) {
      setErrors(errors);
      return;
    }

    emailjs.send('service_j3piyli', 'template_gf79abz', formData, 'vSyDDPMejpTqFslI2')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        alert('Gửi thông tin thành công!');
        setFormData({ phone: '', email: '', name: '', serviceType: '', details: '' });
      })
      .catch((err) => {
        console.error('Failed to send email:', err);
        alert('Lỗi khi gửi thông tin. Vui lòng thử lại sau.');
      });
  };

  const handleMarkerClick = () => {
    const googleMapsLink = "https://www.google.com/maps/search/?api=1&query=21.028511,105.804817";
    window.open(googleMapsLink, '_blank');
  };

  const navigate = useNavigate();

  return (
    <div className={styles.contactPage}>
      <Header activeTab={EHeaderTab.CONTACT} onClick={(tab: EHeaderTab) => navigateHeader(tab, navigate)} />
      <section className={styles.contactFormSection}>
        <h2><span className={styles.highlight}>Arisen</span> luôn sẵn sàng <br /> hỗ trợ bạn</h2>
        <p>Nếu bạn đang tìm kiếm các giải pháp phần mềm để tối ưu hóa hoạt động kinh doanh, liên hệ ngay với chúng tôi!</p>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.inputGrid}>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Số điện thoại"
            />
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Họ và tên"
            />
            <input
              type="text"
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange}
              placeholder="Loại dịch vụ"
            />
            <textarea
              name="details"
              value={formData.details}
              onChange={handleChange}
              placeholder="Chi tiết yêu cầu (không bắt buộc)"
            />
          </div>
          {Object.values(errors).some((error) => error) && (
            <div className={styles.errorMessages}>
              {errors.phone && <div className={styles.error}>{errors.phone}</div>}
              {errors.email && <div className={styles.error}>{errors.email}</div>}
              {errors.name && <div className={styles.error}>{errors.name}</div>}
              {errors.serviceType && <div className={styles.error}>{errors.serviceType}</div>}
            </div>
          )}
          <button type="submit" className={styles.submitButton}>
            Gửi thông tin <ArrowIcon className={styles.iconArrow} />
          </button>
        </form>
      </section>

      <section className={styles.mapSection}>
        <MapContainer center={fixedPosition} zoom={13} className={`${styles.mapContainer}`} attributionControl={false}>
          <ResetMapCenter center={fixedPosition} />
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          <Marker
            position={fixedPosition}
            icon={L.icon({ iconUrl: Icon1, iconSize: [25, 41], iconAnchor: [12, 41] })}
            eventHandlers={{ click: handleMarkerClick }}
          />
        </MapContainer>
      </section>
    </div>
  );
};

export default ContactPage;
