import React from 'react';
import styles from './PageLayout.module.scss';
import Footer from './Footer';
interface IPageLayoutProps {
    children: React.ReactNode;
}

const PageLayout = (props: IPageLayoutProps) => {

    return (
        <div className={styles.container}>
            <div className={styles.body}>
                {props.children}
            </div>
            <div className={styles.footer}>
                <Footer />
            </div>
        </div>
    );
};

export default PageLayout;
