import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { setLoading } from '../redux/loadingSlice';

const useLoading = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 500);
    }, [dispatch]);

    return <></>;
};

export default useLoading;