import { NavigateFunction } from "react-router-dom";
import { EHeaderTab } from "../types/pageLayout";

export const navigateHeader = (tab: EHeaderTab, navigate: NavigateFunction) => {
    switch (tab) {
        case EHeaderTab.HOME:
            navigate('/home');
            break;
        case EHeaderTab.ABOUT:
            navigate('/about');
            break;
        case EHeaderTab.SERVICE:
            navigate('/service');
            break;
        case EHeaderTab.CONTACT:
            navigate('/contact');
            break;
        default:
            break;
    }
    setTimeout(() => {
        const elementId = `header_id`;
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, 1);
};