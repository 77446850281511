import React from "react";
import { SECONDARY_COLOR } from "../../../constants/colors";
import styles from "./Value.module.scss"

interface ValueInfo {
    img: string;
    heading: string;
    description: string;
}

const Value: React.FC<ValueInfo> = ({img, heading, description}) => {
    return (
        <div className={styles.Container}>
            <img src={img} alt={heading} />
            <div className={styles.Text}>
                <h3 style={{color: SECONDARY_COLOR}}>{heading}</h3>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Value;