import Website from '../assets/ImgService/website.png'
import iconx from '../assets/ImgService/design_services.png'
import speed from '../assets/ImgService/avg_pace.png'
import token from '../assets/ImgService/token.png'
import search from '../assets/ImgService/frame_inspect.png'
import mobile from '../assets/ImgService/Icon.png'
import webHook from '../assets/ImgService/webhook.png'
import frameReload from '../assets/ImgService/frame_reload.png'
import IoT from '../assets/ImgService/IoT.png'

const imgService = {
    Website,
    iconx,
    speed,
    token,
    search,
    mobile,
    webHook,
    frameReload,
    IoT,
}

export default imgService;