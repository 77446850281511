import logo from '../assets/Footer/Logo.png'
import backgournd from '../assets/Footer/3.png'
import footer from '../assets/Footer/Footer.png'

const imagesFooter = {
    logo,
    backgournd,
    footer,
}

export default imagesFooter;