import { useAppSelector } from '../../app/hooks'
import styles from './LoadingScreen.module.scss'
import logo from './../../assets/Footer/Logo.png'; // Adjust the path if necessary


const LoadingScreen = () => {

    const isLoading = useAppSelector((state) => state.loading.isLoading)

    if (!isLoading) return <></>;

    return (
        <div className={styles.loadingScreen}>
            <div className={styles.container} >
                <div className={styles.borderOuter}>
                    <div className={styles.borderInner}>
                    </div>
                </div>
                <div className={styles.logoContainer}>
                    <img
                        src={logo} alt="logo"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: 'contain',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen