import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './ServiceCard.module.scss';
import images from '../../constants/imagesHomePage';
import { ReactComponent as ArrowIcon } from '../../assets/images/homePageImages/homePageButtonIcon2.svg';

const ServiceCard = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const cardsContainerRef = useRef<HTMLDivElement>(null);

  const renderIcon = () => {
    return <ArrowIcon className={styles.iconImg} />;
  };

  const services = [
    {
      icon: images.computer,
      title: (
        <>
          Xây dựng <br /> website
        </>
      ),
      description: 'Giải pháp nền tảng số tối ưu nhất.',
      features: [
        'Thiết kế UX/UI, nâng cao trải nghiệm',
        'Tối ưu tốc độ, duy trì tương tác',
        'Chuẩn SEO, nâng cao nhận diện thương hiệu'
      ],
      buttonText: 'Khám phá ngay',
      link: '/service#website-section'
    },
    {
      icon: images.mobile,
      title: 'Phát triển ứng dụng di động',
      description: 'Giải pháp kết nối tốt nhất.',
      features: [
        'Ứng dụng nhanh, tối ưu mọi thiết bị',
        'Kết nối khách hàng mọi lúc, mọi nơi',
        'Giao diện thân thiện, trải nghiệm mượt mà'
      ],
      buttonText: 'Khám phá ngay',
      link: '/service#mobile-section'
    },
    {
      icon: images.cloud,
      title: 'Phát triển phần mềm IoT',
      description: 'Giải pháp công nghệ kết nối thông minh nhất.',
      features: [
        'Giải pháp bảo mật cho thiết bị IoT',
        'Phần mềm tối ưu hiệu suất vượt trội',
        'Tích hợp mượt mà, dễ dàng triển khai'
      ],
      buttonText: 'Khám phá ngay',
      link: '/service#iot-section'
    }
  ];

  const scrollToCard = useCallback((cardIndex: number) => {
    if (cardsContainerRef.current && window.innerWidth <= 768) {
      const cardWidth = cardsContainerRef.current.scrollWidth / services.length;
      cardsContainerRef.current.scrollTo({
        left: cardWidth * cardIndex,
        behavior: 'smooth',
      });
    }
    setCurrentCard(cardIndex);
  }, [services.length]);

  useEffect(() => {
    if (window.innerWidth <= 480) {
      scrollToCard(currentCard);
    }
  }, [currentCard, scrollToCard]);

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Dịch vụ hàng đầu của Arisen</h2>

      <div className={styles.cardContainer} ref={cardsContainerRef}>
        {services.map((service, index) => (
          <div
            key={index}
            className={`${styles.card} ${index === currentCard ? styles.active : ''}`}
          >
            <div className={styles.icon}>
              <img src={service.icon} alt={`${service.title} icon`} className={styles.iconImage} />
            </div>
            <h3 className={styles.title}>{service.title}</h3>
            <h2 className={styles.description}>{service.description}</h2>
            <ul className={styles['features-list']}>
              {service.features.map((feature, idx) => (
                <li key={idx}>{feature}</li>
              ))}
            </ul>
            <Link to={service.link} className={styles.linkButton}>
              <button className={styles.button} >
                {service.buttonText}
                {renderIcon()}
              </button>
            </Link>
          </div>
        ))}
      </div>

      <div className={styles.indicators}>
        {services.map((_, index) => (
          <span
            key={index}
            onClick={() => scrollToCard(index)}
            className={index === currentCard ? styles.activeDot : styles.dot}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default ServiceCard;