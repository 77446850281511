import { Action, ThunkAction, combineSlices, configureStore } from "@reduxjs/toolkit";
import { loadingSlice } from "../redux/loadingSlice";

const rootReducer = combineSlices(
    loadingSlice
);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;