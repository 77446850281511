import React from "react";
import { SECONDARY_COLOR } from '../../../constants/colors';
import styles from './Goals.module.scss';

interface GoalsInfo {
    main: string;
    description: string;
}

const Goal: React.FC<GoalsInfo> = ({main, description}) => {
    return (
        <div className={styles.Container}>
            <h2 style={{color: SECONDARY_COLOR}}>{main}</h2>
            <p style={{color: SECONDARY_COLOR}}>{description}</p>
        </div>
    )
}

export default Goal;