import React from "react";
import styles from "./ButtonsFooter.module.scss";
import { ReactComponent as PhoneIcon } from '../../assets/images/homePageImages/homePageButtonIcon1.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/homePageImages/homePageButtonIcon2.svg';

interface ButtonProps {
  variant: 'orange' | 'black';
  text: string;
}

const Button: React.FC<ButtonProps> = ({ variant, text }) => {
  
  const renderIcon = () => {
    if (variant === 'orange') {
      return <ArrowIcon className={styles.iconArrow} />; 
    }
    if (variant === 'black') {
      return <PhoneIcon className={styles.iconPhone} />; 
    }
    return null;
  };

  return (
    <button className={`${styles.button} ${styles[variant]}`}>
      {text}
      {renderIcon()}
    </button>
  );
};

export default Button;